














































import { defineComponent, inject, ref, computed } from '@vue/composition-api'
import AuthenticationService, {
  AuthenticationServiceToken
} from '@/services/Authentication/AuthenticationService'
import BackLink from '@/components/molecules/BackLink/BackLink.vue'
import TitleGroup from '@/components/molecules/TitleGroup/TitleGroup.vue'
import useForm, { required, email as emailValidation } from '@/hooks/useForm'
import names from '@/router/routes/names'
import i18n from '@/i18n/i18n'
import { Form, Button, InputField, AlertMessage } from '@sennder/plankton'

export default defineComponent({
  name: 'ForgotPassword',
  components: {
    Form,
    Button,
    BackLink,
    InputField,
    TitleGroup,
    AlertMessage
  },
  setup() {
    const service = inject<AuthenticationService>(AuthenticationServiceToken)

    const formSent = ref(false)
    const email = ref('')
    const error = ref(null)

    const title = computed(() => {
      if (formSent.value) {
        return i18n.t('forgotPassword.title.formSent')
      }
      return i18n.t('forgotPassword.title')
    })

    const description = computed(() => {
      if (formSent.value) {
        return i18n.t('forgotPassword.description.formSent')
      }
      return i18n.t('forgotPassword.description')
    })

    const successCallback = async () => {
      try {
        await service?.sendChangePasswordRequest(email.value)
        formSent.value = true
      } catch (err) {
        error.value = err
      }
    }

    const { handleSubmit } = useForm(successCallback)

    const validators = {
      email: [
        required(i18n.t('label.email') as string),
        emailValidation(i18n.t('label.email') as string)
      ]
    }

    return {
      title,
      description,
      email,
      validators,
      error,
      handleSubmit,
      formSent,
      loginRoute: { name: names.login }
    }
  }
})
